import { Typography } from "@mui/material";

function HomePage() {
  return (
    <>
      <Typography component="h2" variant="h4">Home Page!!!</Typography>
      <Typography>Test</Typography>
    </>
  )
}

export default HomePage;
