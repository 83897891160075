import { Typography } from "@mui/material";

function ProductsPage() {
  return (
    <>
      <Typography component="h2" variant="h4">Products Page</Typography>
    </>
  )
}

export default ProductsPage;
